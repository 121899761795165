/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading, Text, Link } from 'theme-ui';

import { graphql, Link as GatsbySlink } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const OrderCancelPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;

  return (
    <>
      <Helmet
        title={t({
          id: `OrderCancel.Title`,
          message: `Order was not placed`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `OrderCancel.MetaDescription`,
            message: `Your order was not placed`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `OrderCancel.BreadcrumbsTitle`,
              message: `Order was not placed`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="OrderCancel.Heading">Your order was not placed</Trans>
        </Heading>

        <Text as="p" mt={[2, 3]}>
          <Trans id="OrderCancel.Description">
            Something went wrong and your order was not placed{' '}
            <span role="img">🤿</span>
            <br /> Please try again or{' '}
            <Link as={GatsbySlink} to="/contact">
              contact us
            </Link>{' '}
            for support.
          </Trans>
        </Text>
      </Box>
    </>
  );
};

export const OrderCancelQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default OrderCancelPage;
